import { css } from 'lit-element';

export const styles = css`
	.terminal-body {
		background: rgb(0, 0, 0, 0.85);
		height: calc(100% - 20px);
		overflow: auto;
	}

	.terminal-header {
		background: #666666;
		height: 20px;

		display: grid;
		grid-template-columns: auto calc(50% + 45px);
	}

	.terminal {
		height: 100%;
		animation-name: fadeIn;
		-webkit-animation-name: fadeIn;

		animation-duration: 1s;
		-webkit-animation-duration: 1s;

		animation-timing-function: ease-in-out;
		-webkit-animation-timing-function: ease-in-out;

		visibility: visible !important;
	}

	.menu-option {
		height: 15px;
		margin: 2px 3px 2px 3px;
		width: 15px;
		border-radius: 0.5rem;
		float: left;
		cursor: pointer;
	}

	.menu-option:hover {
		opacity: 0.7;
	}

	.title {
		color: #d4d4d4;
	}

	@keyframes fadeIn {
		0% {
			transform: scale(0);
			opacity: 0;
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}

	@-webkit-keyframes fadeIn {
		0% {
			-webkit-transform: scale(0);
			opacity: 0;
		}
		100% {
			-webkit-transform: scale(1);
			opacity: 1;
		}
	}
`;
