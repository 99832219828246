import { TerminalMessage } from './';
export class TerminalMessageView {
	private _start: Boolean;
	private _message: TerminalMessage;

	constructor(start: Boolean, message: TerminalMessage) {
		this._start = start;
		this._message = message;
	}

	/**
	 * Getter start
	 * @return {Boolean}
	 */
	public get start(): Boolean {
		return this._start;
	}

	/**
	 * Getter message
	 * @return {TerminalMessage}
	 */
	public get message(): TerminalMessage {
		return this._message;
	}

	/**
	 * Setter start
	 * @param {Boolean} value
	 */
	public set start(value: Boolean) {
		this._start = value;
	}

	/**
	 * Setter message
	 * @param {TerminalMessage} value
	 */
	public set message(value: TerminalMessage) {
		this._message = value;
	}
}
