import { css } from 'lit-element';

export const styles = css`
	.exit-banner {
		height: fit-content;
		width: 40vw;
		padding: 5px;
		margin: auto;
		background: #f2f2f2;
		text-align: center;
		vertical-align: center;
		padding: auto;
		font-family: 'PT Serif', serif;
		border-radius: 30px;
		z-index: 1;
	}

	.exit-banner p {
		font-size: 24px;
	}

	.exit-banner span {
		margin-bottom: 10px;
	}

	.exit-banner div {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	p {
		margin: 0px;
	}

	@media only screen and (max-width: 600px) {
		.container {
			height: 100vh;
			padding: 0;
		}

		.exit-banner {
			transform: translateY(100%);
		}
	}

	.hatch {
		animation-name: hatch;
		-webkit-animation-name: hatch;

		animation-duration: 2s;
		-webkit-animation-duration: 2s;

		animation-timing-function: ease-in-out;
		-webkit-animation-timing-function: ease-in-out;

		transform-origin: 50% 100%;
		-ms-transform-origin: 50% 100%;
		-webkit-transform-origin: 50% 100%;

		visibility: visible !important;
	}

	@keyframes hatch {
		0% {
			transform: rotate(0deg) scaleY(0.6);
		}
		20% {
			transform: rotate(-2deg) scaleY(1.05);
		}
		35% {
			transform: rotate(2deg) scaleY(1);
		}
		50% {
			transform: rotate(-2deg);
		}
		65% {
			transform: rotate(1deg);
		}
		80% {
			transform: rotate(-1deg);
		}
		100% {
			transform: rotate(0deg);
		}
	}

	@-webkit-keyframes hatch {
		0% {
			-webkit-transform: rotate(0deg) scaleY(0.6);
		}
		20% {
			-webkit-transform: rotate(-2deg) scaleY(1.05);
		}
		35% {
			-webkit-transform: rotate(2deg) scaleY(1);
		}
		50% {
			-webkit-transform: rotate(-2deg);
		}
		65% {
			-webkit-transform: rotate(1deg);
		}
		80% {
			-webkit-transform: rotate(-1deg);
		}
		100% {
			-webkit-transform: rotate(0deg);
		}
	}
`;
