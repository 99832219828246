export class TerminalMessage {
	private _message: Array<String>;
	private _lineSeparated: Boolean;
	private _disabled: Boolean;

	constructor(message: Array<String>, lineSeparated: Boolean, disabled: Boolean) {
		this._message = message;
		this._lineSeparated = lineSeparated;
		this._disabled = disabled;
	}

	/**
	 * Getter message
	 * @return {Array<String>}
	 */
	public get message(): Array<String> {
		return this._message;
	}

	/**
	 * Getter lineSeparated
	 * @return {Boolean}
	 */
	public get lineSeparated(): Boolean {
		return this._lineSeparated;
	}

	/**
	 * Getter disabled
	 * @return {Boolean}
	 */
	public get disabled(): Boolean {
		return this._disabled;
	}

	/**
	 * Setter message
	 * @param {Array<String>} value
	 */
	public set message(value: Array<String>) {
		this._message = value;
	}

	/**
	 * Setter lineSeparated
	 * @param {Boolean} value
	 */
	public set lineSeparated(value: Boolean) {
		this._lineSeparated = value;
	}

	/**
	 * Setter disabled
	 * @param {Boolean} value
	 */
	public set disabled(value: Boolean) {
		this._disabled = value;
	}
}
