import { customElement, LitElement, html, property } from 'lit-element';

import { TerminalMessage } from '../../models';
import { styles } from './css';

@customElement('typer-view')
export class TyperView extends LitElement {
	@property({ type: Object })
	message: TerminalMessage = new TerminalMessage([], true, false);

	@property({ type: Boolean })
	startView = false;

	private entry: String = '';

	static get styles() {
		return [styles];
	}

	render() {
		return html`
			<div class="value">
				<div id="display-message">
					${!this.startView
						? this.message.message.map((message: String) => {
								return html`
									${message}${this.message.lineSeparated
										? html`
												<br /><br />
										  `
										: html`
												&nbsp;
										  `}
								`;
						  })
						: html`
								Welcome to my website! Thank you for showing an interest in me!
								<br />
								<br />
								As you can see, I love using unix environments.
								<br />
								<br />
								Feel free to poke around and type in some commands. Only a few are
								supported. If you don't know what to type, start with typing out
								'help'
								<br />
								<br />
								Happy Hacking! &#129409;
						  `}
				</div>
				<br />
				<div class="user-entry">
					${!this.message.disabled
						? html`
								<span class="default-text">visitor@lakshay.xyz ~ %</span>
								<textarea
									?disabled=${this.message.disabled === true}
									@keyup=${this.handleUserInput}
									@keydown=${this.handleKeyDown}
									id="user-entry-text"
								></textarea>
						  `
						: null}
				</div>
			</div>
		`;
	}

	firstUpdated() {
		if (this.message.disabled) {
			let span: HTMLDivElement = this.shadowRoot!.getElementById(
				'display-message',
			) as HTMLDivElement;
			span.scrollIntoView();
		} else {
			let textarea: HTMLTextAreaElement = this.shadowRoot!.getElementById(
				'user-entry-text',
			) as HTMLTextAreaElement;
			textarea.focus();
			window.addEventListener('keyup', (_e: Event) =>
				this.shadowRoot?.getElementById('user-entry-text')?.focus(),
			);
		}
	}

	private handleUserInput(e: KeyboardEvent): void {
		switch (e.key) {
			case 'Enter':
				let target: HTMLTextAreaElement = e.target as HTMLTextAreaElement;
				target.setAttribute('disabled', 'true');

				this.dispatchEvent(
					new CustomEvent('user-input-returned', {
						detail: target.value,
					}),
				);
				break;
			default:
				this.entry += e.key;
				console.log(this.entry);
		}
	}

	private handleKeyDown(e: KeyboardEvent): void {
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	}
}
