import { css } from 'lit-element';

export const styles = css`
	:host {
		font-size: 14px;
	}

	.value {
		color: white;
		padding: 1%;
		font-family: 'Source Code Pro', monospace;
	}

	#user-entry-text {
		background: rgb(0, 0, 0, 0);
		width: 100%;
		color: white;
		border: none;
		resize: none;
		font-size: 14px;
		height: 20px;
		padding: 0;
		font-family: 'Source Code Pro', monospace;
	}

	#user-entry-text:focus {
		outline: none;
	}

	.user-entry {
		display: grid;
		grid-template-columns: 285px auto;
	}

	.default-text {
		width: 280px;
	}
`;
