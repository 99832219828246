import { customElement, LitElement, html, property } from 'lit-element';

import '..//terminal-view/terminal-view';
import { Payload } from '../../models';
import { styles } from './css';

@customElement('sharma-lakshay')
export class SharmaLakshay extends LitElement {
	@property({ type: String })
	height = '';

	@property({ type: Boolean })
	maximize = false;

	@property({ type: Boolean })
	exit = false;

	static get styles() {
		return [styles];
	}

	render() {
		return html`
			<style>
				.container {
					height: ${this.maximize ? '100vh' : '60vh'};
					padding: ${this.maximize ? '0' : '8% 15% 8% 15%'};
					transition: ease-in-out 1s;
				}

				.body {
					background: ${this.exit
						? '#99ccff'
						: 'linear-gradient(to right, #4da6ff, #b3d9ff)'};
					height: 100vh;
					width: 100vw;
				}
			</style>
			<div class="body">
				<div class="container">
					${this.exit
						? html`
								<div class="exit-banner hatch">
									<div>
										<p>
											Thank you for visiting my website! <br />Let's connect!
										</p>
									</div>
									<div>
										<img src="../assets/check.png" height="50px" width="50px" />
									</div>
									<div>
										<span
											><a href="https://www.linkedin.com/in/sharma-lakshay/"
												>LinkedIn</a
											>
											<fa-icon .icon=${'linkedin-square'}></fa-icon>
											or <a href="mailto:lakshay35@gmail.com">Email</a></span
										>
									</div>
								</div>
						  `
						: html`
								<terminal-view
									@terminal-action-clicked=${this.handleTerminalActionClick}
								></terminal-view>
						  `}
				</div>
			</div>
		`;
	}

	constructor() {
		super();
		this.height = '60vh';
	}

	handleTerminalActionClick(e: Payload): void {
		console.log(e.detail);
		switch (e.detail) {
			case 'maximize':
				this.maximize = true;
				break;
			case 'minimize':
				this.maximize = false;
				break;
			default:
				this.exit = true;
		}
	}
}
