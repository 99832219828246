/**
 * Import LitElement base class, html helper function,
 * and TypeScript decorators
 **/
import { LitElement, html, customElement, property } from 'lit-element';
import { repeat } from 'lit-html/directives/repeat';

import '../typer-view/typer-view';
import { Payload, TerminalMessageView, TerminalMessage } from '../../models';
import { styles } from './css';
/**
 * Use the customElement decorator to define your class as
 * a custom element. Registers <my-element> as an HTML tag.
 */
@customElement('terminal-view')
export class TerminalView extends LitElement {
	/**
	 * Create an observed property. Triggers update on change.
	 */
	@property({ type: String })
	foo = 'food';

	@property({ type: Array })
	typerViews: Array<TerminalMessageView> = [
		new TerminalMessageView(true, new TerminalMessage([], false, false)),
	];

	static get styles() {
		return [styles];
	}

	/**
	 * Implement `render` to define a template for your element.
	 */
	render() {
		return html`
			<div class="terminal">
				<div class="terminal-header">
					<div>
						<i
							class="menu-option"
							style="background: #EB4623"
							@click=${(_e: Event) => this.actionClicked('exit')}
						></i>
						<i
							class="menu-option"
							style="background: #F0C609"
							@click=${(_e: Event) => this.actionClicked('minimize')}
						></i>
						<i
							class="menu-option"
							style="background: #3DD508"
							@click=${(_e: Event) => this.actionClicked('maximize')}
						></i>
					</div>
					<div class="title">
						lakshaysharma - zsh
					</div>
				</div>
				<div class="terminal-body">
					${repeat(
						this.typerViews,
						(_view: TerminalMessageView, index: Number) => index,
						(view: TerminalMessageView) => {
							return html`
								<typer-view
									@user-input-returned=${this.handleUserInput}
									.message=${view.message}
									?startView=${view.start === true}
								></typer-view>
							`;
						},
					)}
				</div>
			</div>
		`;
	}

	private actionClicked(action: String): void {
		this.dispatchEvent(
			new CustomEvent('terminal-action-clicked', {
				detail: action,
			}),
		);
	}

	private handleUserInput(input: Payload): void {
		if (input.detail.substr(0, 3) === 'cat') {
			const file = input.detail.split(' ')[1];

			switch (file) {
				case 'about':
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage(
								[
									'Hello visitor, I am an engineering leader in the web3 space. I really enjoy making applications performant and standardized to be scalable in every respect. My areas of focus are web3 and AI',
									'Check out the technologies I have experience in by running `cat skills`',
								],
								true,
								false,
							),
						),
					);
					break;
				case 'experience':
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage(
								[
									'2022 to Present - Genie Labs, Uniswap Labs',
									'2021 to 2022 - Box',
									'2018 to 2021 - ADP',
								],
								true,
								false,
							),
						),
					);
					break;
				case 'skills':
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage(
								[
									'GoLang',
									'Solidity',
									'Typescript',
									'NodeJs',
									'Kafka',
									'AWS',
									'React',
									'Git',
									'HTML/CSS',
								],
								true,
								false,
							),
						),
					);
					break;
				case 'contact':
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage(
								[
									'LinkedIn: https://www.linkedin.com/in/sharma-lakshay/',
									'Twitter: @LakshaySharm',
									'Email: lakshay35@gmail.com',
									'Telegram: @lakshayweb3',
								],
								true,
								false,
							),
						),
					);
					break;
					break;
				case 'resume':
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage(
								['cannot use `cat` command with resume Use `open` instead'],
								false,
								false,
							),
						),
					);
					break;
				default:
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage(['zsh: file not found: ' + file], false, false),
						),
					);
			}
		} else if (input.detail.substr(0, 4) === 'open') {
			const doc = input.detail.split(' ')[1];

			switch (doc) {
				case 'resume':
					document.getElementById('resume')?.click();
					this.typerViews.push(
						new TerminalMessageView(false, new TerminalMessage([], false, false)),
					);
					break;
				default:
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage([`Cannot use 'open' on '${doc}'`], false, false),
						),
					);
			}
		} else {
			switch (input.detail) {
				case 'ls':
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage(
								['about', 'skills', 'resume', 'experience', 'contact'],
								false,
								false,
							),
						),
					);
					break;
				case 'exit':
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage(['Goodbye'], false, true),
						),
					);

					let terminalBody: HTMLDivElement = this.shadowRoot!.querySelector(
						'.terminal-body',
					) as HTMLDivElement;
					terminalBody.scrollTop = terminalBody.scrollHeight;
					break;
				case 'help':
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage(
								[
									'ls - List all files',
									'cat - View the contents of a file, e.g. `cat about`',
									'open - Open files, e.g. `open resume`',
									'exit - Exit the bash window session',
								],
								true,
								false,
							),
						),
					);
					break;
				default:
					this.typerViews.push(
						new TerminalMessageView(
							false,
							new TerminalMessage(
								['zsh: command not found: ' + input.detail],
								false,
								false,
							),
						),
					);
			}
		}

		this.requestUpdate();
	}

	// "build": "yarn build:types && cp index.build.html ./out-tsc/ && mv ./out-tsc/index.build.html ./out-tsc/index.html && rimraf dist && rollup -c rollup.config.js",
}
